import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import GlobalStyles from '../GlobalStyles';
import * as Mixins from '../Mixins';

export const Content = styled.div`
  ${Mixins.contentMixin}
`;

export const LayoutWrapper = styled.div`
  position: relative;
`;

const Layout = ({ children, theme, bigFooter, mediumFooter, openContactPopup }) => (
  <>
    <GlobalStyles />
    <Header theme={theme} openContactPopup={openContactPopup} />
    <LayoutWrapper>{children}</LayoutWrapper>
    <Footer big={bigFooter} medium={mediumFooter} openContactPopup={openContactPopup} />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
  bigFooter: PropTypes.bool,
  mediumFooter: PropTypes.bool
};

export default Layout;
