import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { lighten, darken } from 'polished';
import * as t from '../Typography';
import Colors from '../Colors';
import { media } from '../MediaQueries';

const HireMePopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${Colors.white};
  z-index: 1000;
  display: flex;
  flex-direction: row;
  ${props =>
    props.open === false &&
    `
    display: none;
  `}
  ${media.tablet`
    flex-direction: column;
    overflow-y: auto;
  `};
`;

const Link = styled.a`
  font-size: 18px;
  padding: 16px 50px;
  margin: 0 auto;
  > * {
    width: 300px;
    height: auto;
  }
  ${media.phone`
    > * {
      width: 70%;
    }
  `};
`;

const ContentWrapper = styled.div`
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.tablet`
    display: block;
    padding: 0;
    margin: 0 30px;
  `};
`;

const Burger = styled.div`
  width: 22px;
  height: 22px;
  align-self: center;
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 2;
  color: ${props => props.scrolled && Colors.darkest};
  &:hover {
    ${props => (props.scrolled || props.theme === 'white' ? lighten(0.3, Colors.darkest) : darken(0.1, Colors.white))};
    cursor: pointer;
    opacity: 0.9;
  }
  > * {
    width: 20px;
    height: 20px;
  }
`;

class HireMePopup extends React.Component {
  closePopup = e => {
    this.props.handleClose(false);
  };

  render() {
    const { open } = this.props;
    //const overflow = open ? 'hidden' : 'auto';
    return (
      <HireMePopupWrapper open={open}>
        <StaticQuery
          query={ImageQuery}
          render={data => (
            <ContentWrapper>
              <Burger onClick={this.closePopup}>
                <Img fluid={data.close.childImageSharp.fluid} alt="Close popup" />
              </Burger>
              <t.H3>Contact me on</t.H3>
              <Link primary bold target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/shivams100/">
                <Img fluid={data.ln.childImageSharp.fluid} alt="My Linkedin profile" />
              </Link>
            </ContentWrapper>
          )}
        />
      </HireMePopupWrapper>
    );
  }
}

export default HireMePopup;

const ImageQuery = graphql`
  query {
    close: file(relativePath: { eq: "close-popup-grey.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ln: file(relativePath: { eq: "ln.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
