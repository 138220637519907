import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Facebook from './Facebook';
import Twitter from './Twitter';

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({ title, desc, banner, pathname, article }) => {
  const { site } = useStaticQuery(query);

  const {
    buildTime,
    siteMetadata: {
      url,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      titleTemplate,
      siteLanguage,
      ogLanguage,
      author,
      twitter,
      facebook
    }
  } = site;

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${url}${banner || defaultBanner}`,
    url: `${url}${pathname || ''}`
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: url,
    inLanguage: siteLanguage,
    mainEntityOfPage: url,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author
    },
    creator: {
      '@type': 'Person',
      name: author
    },
    publisher: {
      '@type': 'Person',
      name: author
    },
    datePublished: '2019-10-28T17:27:12+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${url}${defaultBanner}`
    }
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': url,
        name: 'Homepage'
      },
      position: 1
    }
  ];

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement
  };

  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
    </>
  );
};
export default SEO;

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        url
        defaultTitle: title
        defaultDescription: description
        defaultBanner: image
        titleTemplate
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`;
